<template>
  <div>
    <custom-snackbar
      ref="snackbar"
    />

    <v-data-table
      dense
      :headers="header"
      :items="data"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="sigla"
      show-expand
      disable-pagination
      :hide-default-footer="true"
      :search="search"
      class="p-5"
      :loading="loading"
      :loading-text="$dataTableConfig.getLoadText()"
      @item-expanded="expandirProducao"
    >
      >
      <template v-slot:top>
        <v-toolbar flat class="d-flex flex-row-reverse">
          <v-dialog
            v-model="dialog"
            transition="dialog-bottom-transition"
            max-width="600"
          >
            <template v-slot:activator="{}">
              <v-btn color="info" dark outlined @click="openDialog">
                Adicionar Talhão
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-toolbar color="info" dark>
                <span class="headline">{{ formTitle }}</span>
              </v-toolbar>
              <v-form ref="form" v-model="valid" lazy-validation v-if="showForm">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <EmpresasSelect v-model="editedItem.empresa_id" obrigatorio/>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <ProducoesSelect
                          v-model="editedItem.producao_id"
                          :empresa="editedItem.empresa_id"
                          obrigatorio
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="editedItem.cultura_id"
                          :items="culturasOptionsCrud"
                          label="Cultura"
                          :rules="culturaRule"
                          item-text="nome"
                          item-value="id"
                          dense
                          outlined
                          @change="setVariedadesOptions"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="editedItem.variedade_cultura_id"
                          :items="variedadesOptionsCrud"
                          label="Variedade de Cultura"
                          :rules="variedadeRule"
                          item-text="nome"
                          item-value="id"
                          dense
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="editedItem.sistema_irrigacao_id"
                          :items="sirsOptionsCrud"
                          label="Sistema de Irrigação"
                          :rules="sirRule"
                          item-text="nome"
                          item-value="id"
                          dense
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="editedItem.nome"
                          label="Nome"
                          :rules="nomeRule"
                          dense
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="editedItem.area_plantada_m2"
                          label="Área Plantada (m²)"
                         :rules="areaPlantadaRule"
                          @change="setAreaPlantadaH"
                          v-on:keypress="NumbersOnly"

                          dense
                          outlined
                          reverse
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Área Plantada (h)"
                          :value="areaPlantadaH"
                          type="number"
                          dense
                          outlined
                          reverse
                          @change="setAreaPlantadaM2"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="editedItem.formacao"
                          :rules="formacaoRule"
                          type="number"
                          label="Formação (meses)"
                          min="0"
                          dense
                          outlined
                          reverse
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="editedItem.meta_produtividade"
                          :rules="metaRule"
                          type="number"
                          label="Meta Produtividade (%)"
                          dense
                          outlined
                          reverse
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-switch
                          v-model="editedItem.em_producao"
                          inset
                          color="success"
                        >
                          <template v-slot:label>
                            Considerar na Produtividade:
                            <custom-label
                              class="ml-2"
                              :color="getColor(editedItem.em_producao)"
                              :text="getStatus(editedItem.em_producao)"
                            />
                          </template>
                        </v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="validate">
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-form>
              <v-card-text v-else>
                <div class="text-center p-5">
                  <h4 class="mb-4">
                    Aguarde...
                  </h4>
                  <v-progress-circular
                    :size="100"
                    :width="2"
                    color="primary"
                    indeterminate
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card class="bg-danger text-white">
              <v-card-title class="headline word-breaker">
                <p>
                  Tem certeza que deseja excluir o Talhão
                  {{ editedItem.nome }}?
                </p>
              </v-card-title>

              <v-card-text class="d-flex justify-center">
                <v-progress-circular
                  v-show="loadingDelete"
                  :size="50"
                  :width="6"
                  color="white"
                  indeterminate
                />
              </v-card-text>

              <v-card-actions v-show="!loadingDelete">
                <v-spacer />
                <v-btn color="white" text outlined large @click="closeDelete">
                  Não
                </v-btn>
                <v-btn
                  color="white"
                  text
                  outlined
                  large
                  @click="deleteItemConfirm"
                >
                  Sim
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-row class="mt-3 mb-1">
          <v-col class="py-0 mb-2 col-12 col-md-4">
            <EmpresasSelect v-model="empresaFilterValue" mostraTodosLabel/>
          </v-col>
          <v-col class="py-0 mb-2 col-12 col-md-4">
            <ProducoesSelect
              v-model="producaoFilterValue"
              :empresa="empresaFilterValue"
              mostraTodosLabel
            />
          </v-col>
          <v-col class="py-0 mb-2 col-12 col-md-4">
            <v-text-field
              v-model="search"
              outlined
              dense
              label="Pesquisar"
              append-icon="mdi-magnify"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.area_plantada_ha`]="{ item }">
        <span class="num_margin">{{ item.area_plantada_ha }}</span>
      </template>

      <template v-slot:expanded-item="">
        <td colspan="4">
          <v-container>
            <v-data-table
              dense
              :headers="headerTalhoes"
              :items="talhoes"
              :items-per-page="$dataTableConfig.getItemsPerPage()"
              :footer-props="$dataTableConfig.getFooterProps()"
              class="elevation-0"
              :loading="loading2"
              :loading-text="$dataTableConfig.getLoadText()"
            >
              <template v-slot:[`item.area_plantada_ha`]="{ item }">
                <span class="num_margin">{{ item.area_plantada_ha }}</span>
              </template>

              <template v-slot:[`item.formacao`]="{ item }">
                <span class="num_margin">{{ item.formacao }}</span>
              </template>

              <template v-slot:top>
                <v-row class="m-1">
                  <v-col>
                    <v-select
                      v-model="variedadeFilterValue"
                      :items="variedadesOptions"
                      item-text="nome"
                      item-value="nome"
                      outlined
                      dense
                      label="Variedade"
                    />
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="sirFilterValue"
                      :items="sirsOptions"
                      item-text="nome"
                      item-value="nome"
                      outlined
                      dense
                      label="Sistema de Irrigação"
                    />
                  </v-col>
                </v-row>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-container>
        </td>
      </template>
      <template v-slot:no-data>
        <v-spacer />
        <div v-if="!loading">
          <v-row class="justify-center mt-1">
            <v-col class="col-lg-6">
              <custom-alert
                :color="$label.getColor(0)"
                text="Não foi possível obter essas informações"
              />
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<style scoped>
.word-breaker {
  word-break: break-word;
}
</style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: function () {
    return {
      data: [],
      talhoes: [],
      areaTotalH: 0,
      areaPlantadaH: 0,
      instituicoesOptions: [
        {
          nome: 'Todas',
        },
      ],
      producoesOptionsFilter: [
        {
          sigla: 'Todas',
        },
      ],
      variedadesOptions: [
        {
          nome: 'Todas',
        },
      ],
      culturasOptions: [
        {
          nome: 'Todas',
        },
      ],
      sirsOptions: [
        {
          nome: 'Todos',
        },
      ],
      empresasOptionsFilter: [],
      empresasOptions: [],
      producoesOptions: [],
      culturasOptionsCrud: [],
      variedadesOptionsCrud: [],
      sirsOptionsCrud: [],
      maskH: {
        decimal: ',',
        thousands: '',
        prefix: '',
        suffix: '',
        precision: 4,
        masked: true,
      },
      maskMeta: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      em_producao: false,
      valid: true,
      nomeRule: [(v) => !!v || 'Necessário informar o nome do Talhão'],
      producaoRule: [(v) => !!v || 'Necessário informar a Empresa'],
      empresaRule: [(v) => !!v || 'Necessário informar a Produção'],
      culturaRule: [(v) => !!v || 'Necessário informar a Cultura'],
      variedadeRule: [(v) => !!v || 'Necessário informar a Variedade'],
      sirRule: [(v) => !!v || 'Necessário informar o Sistema de Irrigação'],
      areaPlantadaRule: [(v) => (!!v && this.$validations.hasPositiveNumber(this.$stringFormat.convertToFloatNumber(v))) || 'Necessário informar a Área Plantada'],
      formacaoRule: [(v) => (!!v && this.$validations.hasOnlyNumbers(v)) || 'Necessário informar uma Formação válida'],
      metaRule: [(v) => !!v || 'Necessário informar a Meta de Produtividade'],
      search: '',
      empresaFilterValue: '',
      producaoFilterValue: '',
      variedadeFilterValue: '',
      sirFilterValue: '',
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      currentOpenedProducao: -1,
      currentOpenedEmpresa: -1,
      editedItem: {
        empresa_id: 0,
        producao_id: 0,
        cultura_id: 0,
        variedade_cultura_id: 0,
        sistema_irrigacao_id: 0,
        nome: '',
        descricao: '',
        area_total_m2: 0,
        area_plantada_m2: 0,
        formacao: '',
        meta_produtividade: '',
        em_producao: 0,
        status: 1,
      },
      defaultItem: {
        empresa_id: 0,
        producao_id: 0,
        cultura_id: 0,
        variedade_cultura_id: 0,
        sistema_irrigacao_id: 0,
        nome: '',
        descricao: '',
        area_total_m2: 0,
        area_plantada_m2: 0,
        formacao: '',
        meta_produtividade: '',
        em_producao: 0,
        status: 1,
      },
      loading: false,
      loadingDelete: false,
      showForm: true,
    }
  },
  computed: {

    formTitle () {
      return this.editedIndex === -1 ? 'Adicionar Talhão' : 'Editar Talhão'
    },
    filter: (value) => {
      if (!this.em_producao) {
        return true
      }

      return value < parseInt(this.em_producao)
    },
    filteredData () {
      const data = this.data
      return data.filter((d) => d.em_producao === 1)
    },
    expanded () {
      return []
    },
    header () {
      return [
        { text: 'Produção', value: 'id', filter: this.producaoFilter, align: ' d-none' },
        { text: 'Produção', value: 'sigla' },
        {
          text: 'Instituição',
          value: 'empresa_id',
          filter: this.empresaFilter,
          align: ' d-none',
        },
        {
          text: 'Área Plantada (ha)',
          value: 'area_plantada_ha',
          align: 'right',
        },
        {
          text: 'Expandir',
          value: 'data-table-expand',
        },
      ]
    },
    headerTalhoes () {
      return [
        { text: '#', value: 'id' },
        { text: 'Talhão', value: 'nome' },
        {
          text: 'Variedade',
          value: 'variedade.nome',
          filter: this.variedadeFilter,
        },
        {
          text: 'Sistema de Irrigação',
          value: 'sir.nome',
          filter: this.sirFilter,
        },
        { text: 'Formação', value: 'formacao', align: 'right' },
        {
          text: 'Área Plantada (ha)',
          value: 'area_plantada_ha',
          align: 'right',
        },
        { text: 'Ações', value: 'actions', align: 'right' },
      ]
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  created () {
    this.initialize()
  },
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agricola', route: 'agricola' },
      { title: 'Talhões' },
    ])

    this.getProducoes()
    this.getInstituicoesOptions()
    this.getVariedadesOptions()
    this.getCulturasOptions()
    this.getSirOptions()
  },
  methods: {
    initialize () {},
    getProducoes () {
      this.loading = true

      ApiService.get('/talhao').then((res) => {
        this.data = res.data.data
        this.loading = false
      }).catch((error) => {
        this.loading = false
        console.error('Erro: ', error)
      })
    },
    getTalhoes (empresaId, producaoId) {
      this.loading2 = true

      ApiService.get(`/talhao-producao/${empresaId}/${producaoId}`).then(
        (res) => {
          this.talhoes = res.data.data
          this.loading2 = false
        },
      ).catch((error) => {
        this.loading2 = false
        console.error('Erro: ', error)
      })
    },
    NumbersOnly (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode

      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    getInstituicoesOptions () {
      this.empresasOptions = this.$empresas.list(true)
      this.empresasOptionsFilter = this.$empresas.list(true)
    },
    getProducoesOptions () {
      ApiService.get('/talhao').then((res) => {
        const producoes = []

        producoes.push({
          id: '',
          sigla: 'Todas',
        })

        for (const producao of res.data.data) {
          producoes.push({
            id: producao.id,
            sigla: producao.sigla,
          })
        }

        this.producoesOptionsFilter = producoes
      })
    },
    getCulturasOptions () {
      ApiService.get('/cultura').then((res) => {
        this.culturas = res.data.data

        for (const cultura of this.culturas) {
          this.culturasOptions.push(cultura)
          this.culturasOptionsCrud.push(cultura)
        }
      })
    },
    getVariedadesOptions () {
      ApiService.get('/variedade-cultura').then((res) => {
        this.variedades = res.data.data

        for (const variedade of this.variedades) {
          this.variedadesOptions.push(variedade)
        }
      })
    },
    getSirOptions () {
      ApiService.get('/sistema-irrigacao').then((res) => {
        const sirs = res.data.data

        for (const sir of sirs) {
          this.sirsOptions.push(sir)
          this.sirsOptionsCrud.push(sir)
        }
      })
    },
    setVariedadesOptions (idCultura) {
      this.variedadesOptionsCrud = []

      ApiService.get('/variedade-cultura').then((res) => {
        const variedades = res.data.data

        for (const variedade of variedades) {
          if (parseInt(variedade.cultura_id) === parseInt(idCultura)) {
            this.variedadesOptionsCrud.push(variedade)
          }
        }
      })
    },
    setProducoesOptions (idEmpresa) {
      const producaoId = this.producaoFilterValue

      if (idEmpresa !== 'Todas') {
        this.producoesOptions = []

        ApiService.get('/producao').then((res) => {
          const producoes = []
          const producoesFiltro = []
          producoesFiltro.push({ sigla: 'Todas' })

          for (const producao of res.data.data) {
            if (parseInt(producao.empresa_id) === parseInt(idEmpresa)) {
              producoes.push({
                id: producao.id,
                sigla: producao.sigla,
              })

              producoesFiltro.push({
                id: producao.id,
                sigla: producao.sigla,
              })
            }
          }

          this.producoesOptionsFilter = producoesFiltro
          this.producoesOptions = producoes
          this.producaoFilterValue = producaoId === 'Todas' ? 'Todas' : producaoId
        })
      } else {
        const producoesFiltro = []
        producoesFiltro.push({ sigla: 'Todas' })
        this.producoesOptionsFilter = producoesFiltro
        this.producaoFilterValue = 'Todas'
      }
    },
    setAreaTotalH (value) {
      let num = value.split('.').join('')
      num = parseFloat(num).toFixed(4) / 10000

      num = num
        .toString()
        .split('.')
        .join(',')

      this.areaTotalH = num
    },
    setAreaTotalHEdit (value) {
      let num = parseFloat(value).toFixed(4) / 10000

      num = num
        .toString()
        .split('.')
        .join(',')

      this.areaTotalH = num
    },
    setAreaPlantadaH (value) {
      this.editedItem.area_plantada_m2 = this.$stringFormat.formatNumber(this.$stringFormat.convertToFloatNumber(value), 0, 0)
      const num = this.$stringFormat.convertM2ToHectare(value)

      this.areaPlantadaH = num
    },
    setAreaPlantadaHEdit (value) {
      const num = parseFloat(value).toFixed(4) / 10000

      this.areaPlantadaH = num
    },

    setAreaPlantadaM2 (value) {
      const hectare = value
      const m2 = this.$stringFormat.convertHectareToM2(hectare)

      this.editedItem.area_plantada_m2 = m2
    },
    expandirProducao (expanded) {
      if (expanded.value) {
        this.currentOpenedProducao = expanded.item.id
        this.currentOpenedEmpresa = expanded.item.empresa_id
        this.getTalhoes(expanded.item.empresa_id, expanded.item.id)
      }
    },
    empresaFilter (value) {
      if (this.empresaFilterValue === '') {
        return true
      }

      return parseInt(value) === parseInt(this.empresaFilterValue)
    },
    producaoFilter (value) {
      if (this.producaoFilterValue === '' || this.producaoFilterValue === null) {
        return true
      }

      return parseInt(value) === parseInt(this.producaoFilterValue)
    },
    variedadeFilter (value) {
      if (
        this.variedadeFilterValue === '' ||
        this.variedadeFilterValue === 'Todas'
      ) {
        return true
      }

      return value === this.variedadeFilterValue
    },
    sirFilter (value) {
      if (this.sirFilterValue === '' || this.sirFilterValue === 'Todos') {
        return true
      }

      return value === this.sirFilterValue
    },
    getColor (boolField) {
      if (boolField === '0') {
        return 'danger'
      } else {
        return 'success'
      }
    },
    getStatus (emProducao) {
      return emProducao === '0' ? 'Não' : 'Sim'
    },

    validate () {
      if (this.$refs.form.validate()) {
        this.showForm = false
        this.editedItem.descricao = this.editedItem.nome

        if (this.editedIndex > -1) {
          ApiService.put(`/talhao/${this.editedItem.id}`, this.editedItem).then((res) => {
            this.showForm = true
            this.$refs.snackbar.show('Sucesso!', `Talhão ${this.editedItem.nome} atualizado com sucesso`)
            this.afterSave()
            this.close()
          }).catch((error) => {
            this.showForm = true

            if (error.response.data.status === 406) {
              this.$refs.snackbar.show(
                'Atenção',
                'Não é possivel atualizar. Existe Liberação de Colheita ativa para o talhão!',
                'alert',
              )
            } else {
              this.$refs.snackbar.show('Erro', 'Não foi possível atualizar o Talhão', 'danger')
              console.error('Erro: ', error)
            }
          })
        } else {
          ApiService.post('/talhao', this.editedItem).then((res) => {
            this.showForm = true
            this.$refs.snackbar.show('Sucesso!', `Talhão ${this.editedItem.nome} adicionado com sucesso`)
            this.afterSave()
            this.close()
          }).catch((error) => {
            this.showForm = true
            this.$refs.snackbar.show('Erro', 'Não foi possível adicionar o Talhão', 'danger')
            console.error('Erro: ', error)
          })
        }
      }
    },
    afterSave () {
      this.resetForm()
      this.getProducoes()

      if (this.currentOpenedProducao !== -1) {
        this.getTalhoes(this.currentOpenedEmpresa, this.currentOpenedProducao)
      }
    },
    resetForm () {
      if (this.$refs.form !== undefined) {
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }

      this.areaTotalH = 0
      this.areaPlantadaH = 0
    },
    editItem (item) {
      this.dialog = true
      this.editedIndex = this.talhoes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.setProducoesOptions(this.editedItem.empresa_id)
      this.setVariedadesOptions(this.editedItem.cultura_id)
      this.setAreaTotalHEdit(this.editedItem.area_total_m2)
      this.setAreaPlantadaHEdit(this.editedItem.area_plantada_m2)
      this.editedItem.em_producao = parseInt(this.editedItem.em_producao)
      this.editedItem.sistema_irrigacao_id = parseInt(this.editedItem.sistema_irrigacao_id)

      this.editedItem.area_plantada_m2 = this.$stringFormat.formatNumber(this.editedItem.area_plantada_m2, 0, 4)
    },
    deleteItem (item) {
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      this.loadingDelete = true

      ApiService.delete(`/talhao/${this.editedItem.id}`).then(() => {
        this.talhoes.splice(this.editedIndex, 1)
        this.closeDelete()
        this.loadingDelete = false
        this.$refs.snackbar.show('Sucesso!', `Talhão ${this.editedItem.nome} removido com sucesso`)
        this.afterSave()
      }).catch((error) => {
        this.loadingDelete = false
        this.$refs.snackbar.show('Erro', 'Não foi possível remover o Talhão', 'danger')
        console.error('Erro: ', error)
        this.closeDelete()
      })
    },
    openDialog () {
      if (this.$refs.form !== undefined) {
        this.resetForm()
      }

      this.editedItem = this.defaultItem
      this.dialog = true
    },
    close () {
      this.dialog = false

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.producoesOptions = []
        this.$refs.form.resetValidation()
      })
    },
    closeDelete () {
      this.dialogDelete = false

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation()
      })
    },
  },
}
</script>
